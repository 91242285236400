<template>
  <TransformMapperList />
</template>

<script>

import TransformMapperList from '@/components/config/TransformMapperList.vue';

export default {
  name: 'TransformMappers',
  components: {
    TransformMapperList,
  },
};
</script>
