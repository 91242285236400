<template>
    <v-container id="tmc-config-edited-item-container">
      <v-row>
        <v-col cols="3">
          <v-text-field class="transmap-name"
            v-model="editedItem.name"
            @input="emitIsDirty(true)"
            label="Name"
            :rules="required"
            autofocus
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
            id="tmc-transform-edit-dialog-program"
            v-model="editedItem.program"
            :items="programListItems"
            label="Program"
            item-text="label"
            item-value="programKey"
            :rules="required"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select class="transmap-type"
            v-model="editedItem.type"
            @input="emitIsDirty(true)"
            :items="types"
            label="Type"
            :rules="required"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select class="transmap-source"
            v-model="editedItem.source"
            @input="emitIsDirty(true)"
            :items="sources"
            label="Source"
            :rules="required"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select class="transmap-dest"
            v-model="editedItem.destination"
            @input="emitIsDirty(true)"
            :items="destinations"
            item-text="description"
            item-value="action"
            label="Destination"
            :rules="required"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field class="transmap-subProgram"
            v-model="editedItem.subProgram"
            label="Sub Program (Optional)"
          ></v-text-field>
        </v-col>
        <v-col cols="3" v-if="editedItem.type === 'import'">
          <v-text-field class="transmap-subProgram"
            label="Batch Size for Import (Optional)"
            type="number"
            v-model.number="editedItem.batchSize"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col>
          <v-textarea
            id="transformMapperEditItemCustomConfigTextarea"
            class="mx-2 transform-mapper-edit-item-custom-config-textarea"
            label="Custom Configuration"
            dense
            v-model="editedItem.customConfig"
          >
          </v-textarea>
        </v-col> -->
      </v-row>
      <span v-if="editedItem.type === 'export'">
      <v-row v-if="editedItem.source !== 'backend-to-backend'">
        <v-col cols="12">
          <v-textarea
            v-model="editedItem.sourceTemplate"
            name="json-source-template-textarea"
            class="mx-2"
            label="Source Template"
            :rules="required"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
        <v-text-field class="transmap-fileName"
          v-model="editedItem.fileName"
          @input="emitIsDirty(true)"
          label="Export File Name"
          :rules="fileNameRules"
          autofocus
        ></v-text-field>
        </v-col>
        <v-col cols="3">
        <v-text-field class="transmap-collectionName"
          v-model="editedItem.collectionName"
          @input="emitIsDirty(true)"
          label="Collection Name"
          :rules="required"
          autofocus
        ></v-text-field>
        </v-col>
        <v-col cols="3">
        <v-text-field class="transmap-stringFieldNames"
          v-model="editedItem.stringFieldNames"
          @input="emitIsDirty(true)"
          label="String Field Names"
          hint="String Field Names should be comma seperated"
          autofocus
        ></v-text-field>
        </v-col>
      </v-row>
      </span>
      <v-row>
        <v-col cols="12">
          <JsonArrayEditor
            v-model="editedItem.inputs"
            @input="emitIsDirty(true)"
            label="Inputs (JSON)"
            :hint="inputsHint"
            :rules="inputsRules"
          />
        </v-col>
      </v-row>
      <span v-if="editedItem.type === 'import' || 'export'">
      <span v-if="editedItem.type === 'import'">
      <v-row>
        <v-col cols="10">
          <v-text-field
            v-model="editedItem.reportTemplate"
            @input="emitIsDirty(true)"
            label="Report template"
            :hint="'voucherregister?fromDate=${register}&toDate=${anyInputProp}'"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
        <v-checkbox
            small class="mr-2"
            v-model="editedItem.createDependency"
            label="Create dependency"
          ></v-checkbox>
        </v-col>
        <v-col cols="5">
        <v-checkbox
            small class="mr-2"
            v-model="editedItem.backendToBackend"
            label="Backend To Backend"
          ></v-checkbox>
        </v-col>
      </v-row>
      </span>
      <v-row v-if="editedItem.source === 'fixed-width' || editedItem.destination === 'fixed-width'">
        <v-col cols="12">
          <JsonArrayEditor
            v-model="editedItem.fixedWidthTemplate"
            @input="emitIsDirty(true)"
            label="Fixed-width mapping (JSON)"
            :hint="fixedWidthHint"
            :rules="jsonRules"
          />
        </v-col>
      </v-row>
      <v-row v-if="editedItem.type === 'import' || 'export' ">
        <v-col cols="12">
          <JsonArrayEditor
            v-model="editedItem.pipeline"
            @input="emitIsDirty(true)"
            label="Mapping Pipeline (JSON)"
            :rules="jsonRules"
          />
        </v-col>
      </v-row>
      <v-row v-if="editedItem.type === 'import'">
        <v-checkbox
            small class="mr-2"
            v-model="editedItem.showJsonMapper"
            label="Show Json Mapper"
        ></v-checkbox>
      </v-row>
      <v-row v-if="editedItem.showJsonMapper">
        <v-col cols="12">
          <JsonArrayEditor
            v-model="editedItem.jsonMapper"
            @input="emitIsDirty(true)"
            label="Mapping Pipeline (JSON) after Import"
            :hint="pipelineHint"
            :rules="jsonRules"
          />
        </v-col>
      </v-row>
      <v-row v-if="editedItem.type === 'import'">
        <v-col cols="12">
          <TransformMapperPreviewData
            :sourceType="editedItem.source"
            :previewData="editedItem.previewData"
            :baseCrudKey="baseCrudKey"
            @previewed="editedItem.previewData = $event; emitIsDirty(true);"
          />
        </v-col>
      </v-row>
      </span>
      <v-row v-if="editedItem.type === 'export' && editedItem.destination !== 'fixed-width'">
        <v-col cols="12">
          <JsonArrayEditor
            v-model="editedItem.csvInputs"
            @input="emitIsDirty(true)"
            label="Mapping Pipeline (JSON to CSV)"
            :hint="csvInputsHint"
            :rules="jsonRules"
          />
        </v-col>
      </v-row>
      <v-row v-if="isDirty">
        <v-col><span class="red--text text--lighten-1">Unsaved changed</span></v-col>
      </v-row>
    </v-container>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

import {
  sourcesByType,
  types,
} from './TransformMapper.util';

import JsonArrayEditor from '../common/base/JsonArrayEditor.vue';
import TransformMapperPreviewData from './TransformMapperPreviewData.vue';
import { programListForMappper } from '../../util/shared/transform';

export default {
  name: 'TransformMapperEditedItem',
  components: {
    JsonArrayEditor,
    TransformMapperPreviewData,
  },
  data: () => ({
    types,
    programListItems: [...programListForMappper],
  }),
  props: {
    baseCrudKey: {
      type: String,
    },
    isDirty: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'items',
    ]),
    required() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    pipelineHint() {
      return 'Ex: [ { "type": "rename", "prop": "amount1", "to": "amount2" } ]';
    },
    inputsHint() {
      return 'Ex: [ { "type": "date", "prop": "register" } ]';
    },
    csvInputsHint() {
      return 'Ex: [ { "label": "Fiscal Year", "value": "fiscalYear" }, ]';
    },
    fixedWidthHint() {
      return 'Ex: [ { "name": "voucher", "size": 9 } ]';
    },
    fileNameRules() {
      return [
        (value) => !!value || 'Required',
        (value) => {
          const validFileMsg = 'File name extension should be .csv or .txt';
          let result = false;
          if (!(value == null)) {
            result = value.match(/.(?:csv|txt)$/);
          }
          return result ? true : validFileMsg;
        },
      ];
    },
    jsonRules() {
      return [
        (value) => !!value || 'Required',
        (value) => {
          const validJsonMsg = 'Must be a valid JSON array of objects []';
          try {
            const result = JSON.parse(value || '[]');
            return result ? true : validJsonMsg;
          } catch {
            return validJsonMsg;
          }
        },
      ];
    },
    inputsRules() {
      return [
        ...this.jsonRules,
        (value) => {
          const result = JSON.parse(value || '[]');
          if (result.length > 0) {
            const propsList = [];
            for (let i = 0; i < result.length; i += 1) {
              const { type, prop } = result[i];
              if (!prop || propsList.indexOf(prop) > -1) {
                return 'Each input must have a unique prop property.';
              }
              propsList.push(prop);
              if (!type) {
                return 'Each input must have a type property.';
              }
            }
          }
          return true;
        },
      ];
    },
    sources() {
      return sourcesByType(this.editedItem.type);
    },
    destinations() {
      const dests = (this.items(`${this.baseCrudKey}-dests`) || [])
        .filter((i) => i.type === this.editedItem.type);
      return [{ action: '', description: '' }, ...dests];
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapActions('config', [
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
      'setItem',
    ]),
    emitIsDirty(value) {
      this.$emit('isDirty', value);
    },
  },
};
</script>
