<template>
  <div>
    <div v-if="!previewJson"
      class="elevation-1"
    >
      <v-switch
            dense
            v-model="previewJson"
            label="preview"
          ></v-switch>
      <v-textarea
        name="json-textarea"
        class="mx-2"
        :label="label"
        :value="value"
        @input="handleJsonInput"
        :rules="rules"
        :hint="hint"
        dense
      >
      </v-textarea>
    </div>
    <v-data-table v-if="previewJson"
      :items="asItems"
      :headers="headers"
      :caption="label"
      dense
      class="elevation-1"
    >
      <template v-slot:[`item`]="{ item, headers }">
        <CRUDRow
          :canEdit="false"
          :canDelete="false"
          :item="item"
          :headers="headers"
        />
      </template>
      <template v-slot:top>
        <v-switch
          dense
          v-model="previewJson"
          label="preview"
        ></v-switch>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import CRUDRow from './crud/CRUDRow.vue';

export default {
  name: 'JsonArrayEditor',
  components: {
    CRUDRow,
  },
  data: () => ({
    previewJson: false,
    previewMapping: false,
  }),
  props: {
    label: {
      type: String,
      default: 'JSON',
    },
    hint: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '[]',
    },
    rules: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    asItems() {
      return JSON.parse(this.value);
    },
    headers() {
      const headers = this.asItems
        .reduce((ac, i) => {
          const newKeys = Object.keys(i)
            .filter((k) => ac.indexOf(k) === -1);
          return [...ac, ...newKeys];
        }, [])
        .map((h) => ({
          text: h,
          value: h,
        }));
      return headers;
    },
  },
  methods: {
    handleJsonInput(event) {
      this.$emit('input', event);
    },
  },
};

</script>
