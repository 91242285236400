<template>
  <v-container>
    <v-row>
      <v-col>
        <v-textarea
            name="json-preview-textarea"
            class="mx-2"
            label="Preview data"
            :value="previewData"
            @input="emitPreviewText"
            dense
          >
          </v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-file-input
          id="file-json-preview-upload"
          v-model="file"
          :label="`Upload preview data (${sourceType})`"
          :accept="acceptTypes"
          :rules="fileRules"
          show-size
          clearable
          @change="onFileSelected"
        />
      </v-col>
      <v-col cols="2">
        <TransformMapperPreviewImport :baseCrudKey="baseCrudKey" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import TransformMapperPreviewImport from './TransformMapperPreviewImport.vue';

export default {
  name: 'TransformMapperPreviewData',
  components: {
    TransformMapperPreviewImport,
  },
  props: {
    sourceType: {
      type: String,
      default: 'csv',
    },
    previewData: {
      type: String,
      default: '',
    },
    baseCrudKey: {
      type: String,
    },
  },
  data: () => ({
    file: undefined,
  }),
  computed: {
    acceptTypes() {
      switch (this.sourceType) {
        case 'csv':
        case 'csv-headerless': {
          return '.csv';
        }
        default: {
          return '.csv,.txt';
        }
      }
    },
    fileRules() {
      return [
        (files) => {
          const result = !files
            || !files.some((file) => file.size > 524288)
            || 'Preview / test data should be less than 512 kb for performance reasons.';
          return result;
        },
      ];
    },
  },
  methods: {
    onFileSelected(file) {
      if (file) {
        this.fileToString(file);
      }
    },
    fileToString(file) {
      const reader = new FileReader();
      reader.onload = (res) => {
        this.emitPreviewText(res.target.result);
        this.$store.dispatch('flashInfo', 'Uploaded preview file!');
        this.file = undefined;
      };
      reader.onerror = (err) => {
        console.error(err);
        this.$store.dispatch('flashError', 'Unable to open the preview file.');
      };
      reader.readAsText(file);
    },
    emitPreviewText(contents) {
      this.$emit('previewed', contents);
    },
    emitPreviewClicked() {
      this.$emit('clicked');
    },
  },
};
</script>
