<template>
    <v-container>
      <CRUDCriteria
        :showSearch="false"
        :showReset="true"
      >
        <template v-slot:criteriaRows>
          <v-row>
            <v-col cols="3">
              <v-select
                id="tmc-transform-criteria-program"
                v-model="criteriaState.program"
                :items="programListItems"
                label="Program"
                item-text="label"
                item-value="programKey"
                @change="searchByCriteria();"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select id="tmc-transform-criteria-type"
                v-model="criteriaState.type"
                :items="types"
                label="By Transform Mapper Type"
                @change="loadDestLists(); searchByCriteria();"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select id="tmc-transform-criteria-source"
                v-model="criteriaState.source"
                :items="sources"
                label="By Transform Mapper Source"
                @change="searchByCriteria"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select id="tmc-transform-criteria-destination"
                v-model="criteriaState.destination"
                :items="destinations"
                label="By Transform Mapper Destination"
                @change="searchByCriteria"
              ></v-select>
            </v-col>
          </v-row>
        </template>
      </CRUDCriteria>
      <CRUDList
        :canAdd="true"
        :canEdit="true"
        :canSave="true"
        :canApply="true"
        :canDelete="false"
        :baseCrudKey="baseCrudKey"
        :headers="headers"
        :mergeCriteria="false"
        toolbarTitle="Transform Mapper Config"
        @loadCRUDList="handleLoadCRUDList"
        @activateItem="editIsDirty = false"
        @upsertItem="handleUpsertTransformMapper"
        @deleteItem="handleDeleteTransformMapper"
        :defaultSortBy="defaultSortBy"
      >
        <template v-slot:[`editedItem`]="{}">
          <TransformMapperEditedItem
            :baseCrudKey="baseCrudKey"
            :isDirty="editIsDirty"
            @isDirty="editIsDirty = $event" />
        </template>
      </CRUDList>
    </v-container>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';

import CRUDCriteria from '../common/base/crud/CRUDCriteria.vue';
import CRUDList from '../common/base/crud/CRUDList.vue';
import TransformMapperEditedItem from './TransformMapperEditedItem.vue';
import { programListForMappper, transformMapperListFields } from '../../util/shared/transform';

import {
  sourcesByType,
  types,
} from './TransformMapper.util';

const defaultSortBy = [
  {
    column: 'type',
    direction: 'desc',
  },
  {
    column: 'source',
    direction: 'desc',
  },
  {
    column: 'destination',
    direction: 'desc',
  },
];

const destFields = {
  _id: '',
  type: '',
  action: '',
  description: '',
};

const getHeaders = (canDelete) => {
  const headers = [
    { text: 'Type', value: 'type' },
    { text: 'Name', value: 'name' },
    { text: 'Source', value: 'source' },
    { text: 'Destination', value: 'destination' },
  ];
  if (canDelete) {
    headers.push({ text: 'Actions', value: 'actions', sortable: false });
  }
  return headers;
};

export default {
  name: 'TransformMapperList',
  components: {
    CRUDCriteria,
    CRUDList,
    TransformMapperEditedItem,
  },
  data: () => ({
    baseCrudKey: 'transform-mapper-list',
    headers: [], // set by getHeaders
    types,
    defaultSortBy,
    editIsDirty: false,
    programListItems: [
      { label: '', programKey: null },
      ...programListForMappper,
    ],
  }),
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'items',
    ]),
    sources() {
      return sourcesByType(this.criteriaState.type);
    },
    destinations() {
      const dests = (this.items(`${this.baseCrudKey}-dests`) || [])
        .filter((i) => i.type === this.criteriaState.type)
        .map((i) => i.action);
      return ['', ...dests];
    },
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
  },
  created() {
    this.headers = getHeaders(this.canDelete);
    this.loadDestLists();
  },
  methods: {
    ...mapActions('config', [
      'loadDests',
      'loadTransformMappers',
      'upsertTransformMapper',
      'deleteTransformMapper',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    loadDestLists() {
      this.loadDests({
        fields: destFields,
        baseCrudKey: `${this.baseCrudKey}`,
      });
    },
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteria(baseCrudKey);
      this.loadTransformMappers({
        criteria,
        fields: { ...transformMapperListFields },
        baseCrudKey,
      });
    },
    // saveableEdits(item) {
    //   const updatedItem = { ...item };
    //   const response = parseJson(item.customConfig);
    //   updatedItem.customConfig = response.isResParsed ? response.parsedResponse : {};
    //   return updatedItem;
    // },
    handleUpsertTransformMapper(item) {
      const { baseCrudKey } = this;
      // const item = this.saveableEdits(itemForUpsert);
      this.upsertTransformMapper({
        item,
        fields: { ...transformMapperListFields },
        baseCrudKey,
      });
      this.editIsDirty = false;
    },
    handleDeleteTransformMapper(item) {
      const { baseCrudKey } = this;
      this.deleteTransformMapper({
        item,
        baseCrudKey,
      });
    },
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
  },
};
</script>
